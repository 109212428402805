<template>
  <global-card
    v-if="user"
    :title="title"
    :with-actions="false"
    :heading-color="headingColor"
    :show-heading="showHeading"
    :fill-height="fillHeight"
  >
    <div
      class="d-flex flex-column align-center text-center"
      :class="{ 'mt-3': title }"
    >
      <user-avatar
        :zoom-enabled="zoomEnabled"
        :user="user"
        :size="xs ? '80' : '150'"
      />
      <h4 :class="xs ? 'text-body-1 text-primary-darken-1' : 'text-h6'">
        {{ user.full_name }}
      </h4>

      <phone-number
        :number="user.mobile_phone"
        :size="xs ? 'x-small' : 'small'"
      />

      <v-btn
        v-if="showEmail"
        class="pl-2 text-lowercase"
        :href="`mailto:${user.email}`"
        prepend-icon="fas fa-envelope"
        variant="plain"
        size="large"
        :text="user.email"
      />
    </div>
  </global-card>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify';
import UserAvatar from '../images/UserAvatar.vue';

withDefaults(defineProps<{
  user: any;
  title?: string;
  headingColor?: string;
  showEmail?: boolean;
  showHeading?: boolean;
  fillHeight?: boolean;
  zoomEnabled?: boolean;
}>(), {
  headingColor: () => 'primary',
  showEmail: () => true,
  showHeading: () => true,
  fillheight: () => true,
  zoomEnabled: () => false,
});
const { xs } = useDisplay();
</script>
